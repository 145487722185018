/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 19:09:23
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-22 10:29:42
 */
import { mapGetters, mapActions } from 'vuex'
import { getNormalWeek, numberFormat, moneyFormat } from '@/plugins/utils'
import EnumMap from '@/plugins/enumMap'
import eventBus from '@/service/eventBus'
export default {
  name: 'FinanceTable',
  data () {
    return {
      date: [],
      queryForm: {
        start_date: '', // 开始时间
        end_date: '', // 结束时间
        pay_status: 0, // 支付状态
        finance_record_type: 0, // 交易类型
        field_type: '',
        sort_type: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'enum_map'
    ])
  },
  watch: {
    date (val, old) {
      if (val) {
        this.$set(this.queryForm, 'start_date', val[0])
        this.$set(this.queryForm, 'end_date', val[1])
      }
    }
  },
  methods: {
    ...mapActions([
      'getAgentAccountInfo'
    ]),
    numFormat (val) {
      return numberFormat(val)
    },
    moneyFormat,
    activityStatus (val) {
      const name = new EnumMap('pay_status').mapping(val)
      return name
    },
    tableSearch (reset, sort) {
      if (!sort) {
        this.queryForm.field_type = ''
        this.queryForm.sort_type = ''
      }
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    tableSort (val) {
      this.queryForm.field_type = val.fieldType
      this.queryForm.sort_type = val.sort_type
      this.tableSearch(true, true)
    }
  },
  mounted () {
    eventBus.$on('app-finance-refresh', () => {
      console.log('table refresh')
      this.getAgentAccountInfo()
      this.tableSearch(true)
    })
  },
  created () {
    this.date = getNormalWeek(true)
  }
}
