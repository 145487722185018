/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 19:08:55
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 19:10:08
 */
import FinanceTable from './FinanceTable.vue'
export default FinanceTable
